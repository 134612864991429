import { createRoot } from 'react-dom/client'
import HelloWorld from './components/HelloWorld'
import LatestNews from './components/LatestNews'

// import ReactDOM from "react-dom";
console.log('yay react!')

const helloWorldComponent = document.getElementById('yayReact')
if (helloWorldComponent) {
	const root = createRoot(helloWorldComponent)
	root.render(<HelloWorld />)
}

const latestNewsComponent = document.getElementById('reactNews')
if (latestNewsComponent) {
	const root = createRoot(latestNewsComponent)
	root.render(<LatestNews />)
}
