// LatestNews.js
import React, { useState, useEffect } from 'react'
import newsData from '../newsData.json'

const ITEMS_PER_PAGE = 6
const LARGE_BREAKPOINT = 1200

const LatestNews = () => {
	const [currentPage, setCurrentPage] = useState(1)
	const [selectedCategory, setSelectedCategory] = useState(null)
	const [isMobileView, setIsMobileView] = useState(
		window.innerWidth <= LARGE_BREAKPOINT
	)
	const totalPosts = newsData.articles.length

	useEffect(() => {
		// Calculate the total number of posts based on the selected category
		setSelectedCategory(null) // Reset selected category to show total posts
		setCurrentPage(1) // Reset pagination when a new category is selected

		const handleWindowResize = () => {
			setIsMobileView(window.innerWidth <= LARGE_BREAKPOINT)
		}

		window.addEventListener('resize', handleWindowResize)

		// Cleanup the event listener on component unmount
		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	}, [])

	const handleCategoryFilter = category => {
		setSelectedCategory(category)
		setCurrentPage(1) // Reset pagination when a new category is selected
	}

	const filteredArticles = selectedCategory
		? newsData.articles.filter(
				article => article.category === selectedCategory
		  )
		: newsData.articles

	const totalItems = filteredArticles.length
	const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE)
	const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
	const endIndex = startIndex + ITEMS_PER_PAGE
	const displayedArticles = filteredArticles.slice(startIndex, endIndex)

	const handlePrevPage = () => {
		if (currentPage > 1) {
			setCurrentPage(prevPage => prevPage - 1)
		}
	}

	const handleNextPage = () => {
		if (currentPage < totalPages) {
			setCurrentPage(prevPage => prevPage + 1)
		}
	}

	return (
		<div className='latest-news-component'>
			<div className='latest-news-header'>
				<div className='flex-row'>
					<h2>Latest News</h2>
					<div className='post-count'>{totalPosts} posts</div>
				</div>
				{isMobileView ? (
					<select
						class='mobile-dropdown-selector'
						value={selectedCategory || 'all'}
						onChange={e =>
							handleCategoryFilter(
								e.target.value === 'all' ? null : e.target.value
							)
						}
					>
						<option class='super-header' value='all'>
							All Posts
						</option>
						<option class='super-header' value='News'>
							News
						</option>
						<option class='super-header' value='People'>
							People
						</option>
						<option class='super-header' value='New Technology'>
							New Technology
						</option>
						<option class='super-header' value='Events'>
							Events
						</option>
					</select>
				) : (
					<div className='category-buttons'>
						<p className='super-header'>Filters</p>
						<a
							className={`super-header ${
								selectedCategory === null ? 'active' : ''
							}`}
							onClick={() => handleCategoryFilter(null)}
						>
							All Posts
						</a>
						<a
							className={`super-header ${
								selectedCategory === 'News' ? 'active' : ''
							}`}
							onClick={() => handleCategoryFilter('News')}
						>
							News
						</a>
						<a
							className={`super-header ${
								selectedCategory === 'People' ? 'active' : ''
							}`}
							onClick={() => handleCategoryFilter('People')}
						>
							People
						</a>
						<a
							className={`super-header ${
								selectedCategory === 'New Technology'
									? 'active'
									: ''
							}`}
							onClick={() =>
								handleCategoryFilter('New Technology')
							}
						>
							New Technology
						</a>
						<a
							className={`super-header ${
								selectedCategory === 'Events' ? 'active' : ''
							}`}
							onClick={() => handleCategoryFilter('Events')}
						>
							Events
						</a>
					</div>
				)}
			</div>
			<div className='latest-news-list'>
				{displayedArticles.map(article => (
					<div className='news-article' key={article.id}>
						<img src={article.image} alt={article.title} />
						<div className='article-content'>
							<p className='category super-header'>
								{article.category}
							</p>
							<p className='h3'>{article.title}</p>
							<p className='description'>{article.description}</p>
							<button className='button link'>
								Read More<span className='arrow'></span>
							</button>
						</div>
					</div>
				))}
			</div>
			<div className='pagination'>
				<a
					disabled={currentPage === 1}
					onClick={handlePrevPage}
					className={`pagination-arrow previous ${
						currentPage === 1 ? 'disabled' : ''
					}`}
				>
					<span className='arrow left'></span>Previous
				</a>
				{Array.from({ length: totalPages }, (_, index) => (
					<a
						key={index + 1}
						onClick={() => setCurrentPage(index + 1)}
						className={currentPage === index + 1 ? 'active' : ''}
					>
						{index + 1}
					</a>
				))}
				<a
					disabled={currentPage === totalPages}
					onClick={handleNextPage}
					className={`pagination-arrow next ${
						currentPage === totalPages ? 'disabled' : ''
					}`}
				>
					Next <span className='arrow'></span>
				</a>
			</div>
		</div>
	)
}

export default LatestNews
